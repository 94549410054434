import { ensureTrailingSlash } from "./ensureTrailingSlash";

interface EnvConfig {
  IS_PROD: boolean | null;
  API_BASE_URL: string | null;
  INIT_DATE: string | null;
}

declare global {
  interface Window {
    env: Partial<EnvConfig>;
  }
}

export const environment = {
  IS_PROD: window['env']['IS_PROD'] || false,
  API_BASE_URL: ensureTrailingSlash(window['env']['API_BASE_URL']) || 'https://dev.portal-evidencias-api.kn.finwave.es/',
  INIT_DATE: window['env']['INIT_DATE'] || '2023-09-10'
};
